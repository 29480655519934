<template>
    <div class="relative">
        <div>
            <input
                ref="input"
                v-theme="['form.accent', 'form.text']"
                :disabled="isDisabled"
                class="block w-full appearance-none outline-none py-2 px-4 border rounded-lg bg-transparent leading-normal transition duration-150 ease-in-out"
                :class="textClass"
                :type="type"
                :placeholder="placeholder"
                :value="value"
                @input="$emit('input', $event.target.value)"
                @keydown.enter="$emit('complete')"
            >
            <div
                style="top:8px;right:0;"
                class="absolute opacity-50 hidden md:block"
                :class="textClass"
            >
                <slot name="icon"></slot>
            </div>

            <div v-if="showError" class="alert alert-error alert-sm mt-2">
                <p class="w-full font-normal text-center">{{ error }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import HandleErrorMessages from '@/mixins/HandleErrorMessages';

export default {
    mixins: [HandleErrorMessages],

    props: {
        isDisabled: {
            type: Boolean,
            default: false
        },

        textClass: {
            type: String,
            default: 'text-lg'
        },

        type: {
            type: String,
            default: 'text'
        },

        value: {
            type: String,
            required: true
        },

        placeholder: {
            type: String,
            required: true
        }
    },

    data () {
        return {
            text: this.value
        };
    },

    methods: {
        focus () {
            this.$refs.input.focus();
        }
    }
};
</script>
