<template>
    <div class="px-6 py-2">
        <div class="block md:flex md:flex-wrap space-y-2 space-x-0">
            <div v-if="showTitleDropdown">
                <title-picker
                    v-model="guest.title_id"
                    v-theme="['form.dropdown']"
                    :placeholder="$t('placeholder-title')"
                    style="background-color:rgba(0, 50, 0, 0)"
                    :class="textClass"
                    class="mt-2 pr-2"
                    form-field-class="form-field w-32"
                ></title-picker>
            </div>

            <template v-if="requireNameForEachGuest">
                <simple-picker
                    v-if="showInviteeSelectors && !editingInviteeName"
                    v-theme="['form.dropdown']"
                    :value="selectedInvitee"
                    :items="invitees"
                    :starting-highlighted-index="-1"
                    :is-item-disabled-function="isInviteeSelectionDisabled"
                    :placeholder-empty-state="$t('text-select-guest')"
                    class="text-xl"
                    :class="showGuestDetailsFields ? 'w-full' : 'flex-1'"
                    @input="selectInvitee"
                >
                    <div
                        slot="input"
                        slot-scope="{ selected }"
                    >
                        {{ displayName(selected) }}
                    </div>
                    <div
                        slot="item"
                        slot-scope="{ item }"
                    >
                        {{ displayName(item) }}
                    </div>
                </simple-picker>

                <template v-if="showGuestDetailsFields">
                    <div class="flex space-x-2 w-full">
                        <div class="flex-1 w-full md:mr-4">
                            <single-line-text-input
                                v-model="guest.first_name"
                                :placeholder="$t('placeholder-first-name')"
                                :text-class="inputClass"
                                @complete="saveInviteeDetails"
                            ></single-line-text-input>
                        </div>

                        <div class="flex-1">
                            <single-line-text-input
                                v-model="guest.last_name"
                                :placeholder="$t('placeholder-last-name')"
                                :text-class="inputClass"
                                @complete="saveInviteeDetails"
                            ></single-line-text-input>
                        </div>

                        <div
                            v-if="editingInviteeName"
                            v-theme="['form.title-text', 'form.accent']"
                            class="h-full"
                        >
                            <button class="h-full button-text accent-text border rounded-lg p-2" @click="saveInviteeDetails">
                                <app-icon
                                    class="h-7 w-7"
                                    name="check-circle"
                                ></app-icon>
                            </button>
                        </div>
                    </div>
                </template>
            </template>
        </div>

        <div
            v-if="showEditNameButton"
            v-theme="'form.accented-text'"
            class="text-right text-sm"
        >
            <button class="accent-text" @click="editingInviteeName = true">
                {{ $t('button-edit-name') }}
            </button>
        </div>

        <single-line-text-input
            v-if="requireEmail"
            v-model="guest.email"
            class="mt-2 mb-8"
            :text-class="inputClass"
            :placeholder="$t('placeholder-email-address')"
        >
            <app-icon
                slot="icon"
                v-theme="'form.accented-text'"
                name="email"
                class="h-6 w-6 mr-2"
            >
            </app-icon>
        </single-line-text-input>

        <div v-if="showError" class="alert alert-error alert-sm mt-2">
            <p class="w-full font-normal text-center">{{ error }}</p>
        </div>
    </div>
</template>

<script>
import find from 'lodash/find';
import { get } from 'vuex-pathify';
import HandleErrorMessages from '@/mixins/HandleErrorMessages';

export default {
    name: 'GuestDetails',

    mixins: [HandleErrorMessages],

    props: {
        allowInviteeNameEditing: {
            type: Boolean,
            default: false
        },
        invitees: {
            type: Array,
            default: () => { return []; }
        },
        requireEmail: {
            type: Boolean,
            default: true
        },
        requireName: {
            type: Boolean,
            default: true
        },
        requireTitle: {
            type: Boolean,
            default: true
        },
        textClass: {
            type: String,
            default: 'text-xl'
        },
        showInviteeSelectors: {
            type: Boolean,
            default: false
        },
        value: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            guest: {
                ...this.value
            },
            editingInviteeName: false
        };
    },

    computed: {
        blocks: get('Form/form@blocks'),
        event: get('Event/event'),

        requireNameForEachGuest () {
            // If we're in the invitee flow, we're always requiring name details
            if (this.showInviteeSelectors) {
                return true;
            }

            return this.requireName;
        },

        selectedInvitee () {
            if (this.guest.parent_invitee_id) {
                return find(this.invitees, { parent_invitee_id: this.guest.parent_invitee_id });
            }

            return find(this.invitees, { id: this.guest.invitee_id });
        },

        showTitleDropdown () {
            return this.requireName && this.requireTitle;
        },

        showEditNameButton () {
            // If we're already in edit mode, or an invitee hasn't been selected
            // yet, or this is an additional guest, do not show the Edit Button
            if (this.editingInviteeName || !this.selectedInvitee || this.guest.parent_invitee_id) {
                return false;
            }

            return this.allowInviteeNameEditing;
        },

        showGuestDetailsFields () {
            if (!this.showInviteeSelectors) {
                return true;
            }

            return this.editingInviteeName || (this.requireName && this.guest.parent_invitee_id);
        },

        inputClass () {
            if (this.editingInviteeName) {
                return `${this.textClass} no-mousetrap`;
            }

            return this.textClass;
        },

        useFormalName () {
            const primaryBlockType = this.event.setup_type === 'tickets' ? 'ticketing' : 'rsvp';
            const primaryBlock = find(this.blocks, { slug: primaryBlockType });
            return primaryBlock.pivot.settings.guestTitles.enabled;
        }
    },

    watch: {
        guest: {
            deep: true,
            handler () {
                this.$emit('input', this.guest);
            }
        },

        'value.invitee_id': function (id) {
            if (this.guest.invitee_id !== id) {
                this.selectInvitee(find(this.invitees, { id }));
            }
        },

        'value.parent_invitee_id': function (parentInviteeId) {
            if (this.guest.parent_invitee_id !== parentInviteeId) {
                this.selectInvitee(find(this.invitees, { parent_invitee_id: parentInviteeId }));
            }
        }
    },

    methods: {
        displayName (selected) {
            if (this.showTitleDropdown && !selected.parent_invitee_id) {
                return selected.fullName;
            }

            if (selected.isAdditionalGuest) {
                return selected.formalName;
            }

            if (this.guestHasFormalName(selected) && this.useFormalName) {
                return selected.formalName;
            }

            if (this.guestHasFullName(selected)) {
                return selected.fullName;
            }

            return `Guest (${selected.email})`;
        },

        guestHasFormalName (guest) {
            return guest.formalName !== null && guest.formalName !== ' ';
        },

        guestHasFullName (guest) {
            return guest.fullName !== null && guest.fullName !== ' ';
        },

        selectInvitee (invitee) {
            if (!invitee) {
                return;
            }

            this.guest.parent_invitee_id = null;
            this.guest.invitee_id = null;

            if (invitee.isAdditionalGuest) {
                const parentInvitee = find(this.invitees, { id: invitee.parent_invitee_id });

                this.guest.parent_invitee_id = parentInvitee.id;
                this.guest.first_name = '';
                this.guest.last_name = '';
                this.guest.uuid = parentInvitee.uuid;
            } else {
                this.guest.invitee_id = invitee.id;
                this.guest.title_id = invitee.title_id;
                this.guest.first_name = invitee.first_name;
                this.guest.last_name = invitee.last_name;
                this.guest.email = invitee.email;
                this.guest.uuid = invitee.uuid;
            }

            this.$nextTick(() => {
                this.$emit('selected-invitee');
            });
        },

        isInviteeSelectionDisabled (invitee) {
            return !invitee.available;
        },

        saveInviteeDetails () {
            this.$emit('updated-invitee-name', this.guest);

            this.$nextTick(() => {
                this.editingInviteeName = false;
            });
        }
    }
};
</script>
