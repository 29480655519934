<template>
    <div class="w-full inline-block">
        <div
            class="h-full flex items-center"
            :class="alignmentStyles"
            @click="deselect"
        >
            <span
                ref="image"
                class="relative inline-block clear-both box-border whitespace-normal"
                :data-text-align="node.attrs.textAlign"
                :class="imageContainerStyles"
                @click.stop="select"
            >
                <img
                    class="block"
                    :src="node.attrs.loader ? asset('/images/svg/loader.svg') : src"
                    :width="node.attrs.width"
                    :height="node.attrs.height"
                    :caption="node.attrs.caption"
                    :alt="imageOptions.alt"
                    :class="{ border: selected && editable, 'w-20 h-20': node.attrs.loader }"
                    @click="selectImage"
                >

                <div
                    v-show="(selected && editable) || resizing"
                    class="image-resizer absolute w-full h-full left-0 top-0"
                >
                    <span
                        v-for="(direction, index) in resizeDirections"
                        :key="index"
                        :class="`resizer-box-${direction}`"
                        class="bg-purple-light border border-white box-border block w-3 h-3 absolute"
                        @mousedown="onMouseDown($event, direction)"
                    ></span>
                </div>
            </span>
        </div>

        <portal v-if="selected && editable" to="modal-portal-target">
            <div
                ref="dropdown"
                class="absolute z-60 bg-black border-b-2 border-purple-light rounded-xl p-4 w-64 whitespace-normal"
            >
                <div class="flex flex-col grow">
                    <div class="flex flex-wrap">
                        <div class="w-full">
                            <label class="flex items-center justify-between relative uppercase tracking-wide text-xs font-semibold text-snow-700">
                                Size
                            </label>

                            <div class="flex w-full items-center pt-1">
                                <div class="mr-2">
                                    <input
                                        v-model="imageOptions.width"
                                        class="form-field text-snow-700 text-sm p-1 bg-transparent"
                                        placeholder="300"
                                        @input="() => debouncedUpdateSize('width')"
                                    >

                                    <div class="mt-1 ml-1 text-xs text-white opacity-50">Width (px)</div>
                                </div>

                                <div>
                                    <input
                                        v-model="imageOptions.height"
                                        class="form-field text-snow-700 text-sm p-1 bg-transparent"
                                        placeholder="300"
                                        @input="() => debouncedUpdateSize('height')"
                                    >

                                    <div class="mt-1 ml-1 text-xs text-white opacity-50">Height (px)</div>
                                </div>
                            </div>

                            <div class="w-full mt-4">
                                <label class="flex items-center justify-between relative uppercase tracking-wide text-xs font-semibold text-snow-700">
                                    Link
                                </label>

                                <div class="flex w-full items-center pt-1">
                                    <input
                                        v-model="imageLink"
                                        class="form-field text-snow-700 text-sm p-1 bg-transparent"
                                        placeholder="https://"
                                        @keyup.enter="updateLink(imageLink)"
                                    >

                                    <button
                                        v-if="canSaveLink"
                                        class="flex items-center ml-2 text-white"
                                        @click.prevent="updateLink(imageLink)"
                                    >
                                        <app-icon name="check-circle" class="h-4 w-4"></app-icon>
                                    </button>

                                    <button
                                        class="flex items-center ml-2 text-white"
                                        @click.stop.prevent="removeLink"
                                    >
                                        <app-icon name="close-circle" class="h-4 w-4"></app-icon>
                                    </button>
                                </div>
                            </div>

                            <div class="w-full mt-4">
                                <label class="flex items-center justify-between relative uppercase tracking-wide text-xs font-semibold text-snow-700">
                                    Alt Text
                                </label>

                                <input
                                    v-model="imageOptions.alt"
                                    class="form-field text-snow-700 text-sm p-1 bg-transparent flex w-full items-center pt-1"
                                    placeholder="Event invitation"
                                    @input="updateAltText(imageOptions.alt)"
                                >
                            </div>

                            <div class="w-full mt-4">
                                <label class="flex items-center justify-between relative uppercase tracking-wide text-xs font-semibold text-snow-700">
                                    Alignment
                                </label>

                                <div class="block relative w-full pt-1">
                                    <div class="flex justify-between items-center">
                                        <button @click.prevent="align('left')">
                                            <app-icon
                                                name="paragraph-align-left-stroke"
                                                class="h-5 w-5 text-white"
                                            ></app-icon>
                                        </button>
                                        <button @click.prevent="align('center')">
                                            <app-icon
                                                name="paragraph-align-center-stroke"
                                                class="h-5 w-5 text-white"
                                            ></app-icon>
                                        </button>
                                        <button @click.prevent="align('right')">
                                            <app-icon
                                                name="paragraph-align-right-stroke"
                                                class="h-5 w-5 text-white"
                                            ></app-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </portal>
    </div>
</template>

<script>
import { NodeSelection } from 'prosemirror-state';
import debounce from 'lodash/debounce';
import clamp from 'lodash/clamp';
import { createPopper } from '@popperjs/core';
import { TextSelection } from 'tiptap';
import ValidatesLinks from '@/mixins/ValidatesLinks';
import { resolveImage } from '../Utils/resolveImage';

export default {
    name: 'EditorImage',

    mixins: [ValidatesLinks],

    props: {
        editor: {
            type: Object,
            required: true
        },

        getPos: {
            type: Function,
            required: true
        },

        node: {
            type: Object,
            required: true
        },

        selected: {
            type: Boolean,
            required: true
        },

        updateAttrs: {
            type: Function,
            required: true
        },

        view: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            currentImageLink: '',
            imageLink: '',
            imageOptions: {
                src: '',
                alt: '',
                caption: '',
                ref: '',
                width: 0,
                height: 0,
                originalWidth: 0,
                originalHeight: 0
            },
            maxWidth: 0,
            resizing: false,
            resizerState: {
                x: 0,
                y: 0,
                w: 0,
                h: 0,
                dir: ''
            },
            popper: null
        };
    },

    computed: {
        editable () {
            return this.editor && this.editor.options.editable && !this.node.attrs.loader;
        },

        alignmentStyles () {
            const { alignment } = this.node.attrs;

            return {
                'justify-start': alignment === 'left',
                'justify-center': alignment === 'center',
                'justify-end': alignment === 'right'
            };
        },

        imageContainerStyles () {
            return {
                'border border-purple-light': (this.selected && this.editable) || this.resizing
            };
        },

        canSaveLink () {
            return this.imageLink !== this.currentImageLink;
        },

        resizeDirections () {
            return [
                'tl', 'tr', 'bl', 'br'
            ];
        },

        src () {
            return this.node.attrs.src;
        }
    },

    watch: {
        selected (newVal) {
            if (!this.editable) {
                return;
            }

            if (newVal) {
                this.$nextTick(() => {
                    this.getLink();

                    this.$nextTick(() => {
                        if (this.popper) {
                            this.popper.update();
                        } else {
                            this.initializePopper();
                        }
                    });
                });
            } else {
                this.destroyPopper();
            }
        },

        src () {
            this.setImageOptions();
        }
    },

    mounted () {
        this.setImageMaxSize();
    },

    created () {
        this.setImageOptions();
    },

    methods: {
        align (alignment) {
            this.updateAttrs({ alignment });

            this.editor.commands.alignment({ align: alignment });
        },

        debouncedUpdateSize: debounce(function (type) {
            this.updateSize(type);
        }, 250),

        destroyPopper () {
            this.popper?.destroy();
            this.popper = null;
        },

        deselect () {
            const { tr } = this.view.state;

            const transaction = tr.setSelection(new TextSelection(this.view.state.doc.resolve(0)));

            this.view.dispatch(transaction);
        },

        getLink () {
            const linkAttrs = this.editor.getMarkAttrs('link');

            if (linkAttrs && linkAttrs.href) {
                this.currentImageLink = linkAttrs.href;
                this.imageLink = this.currentImageLink;
            }
        },

        setImageSize (width, height) {
            this.updateAttrs({
                width,
                height
            });
        },

        initializePopper () {
            if (this.popper) {
                return;
            }

            this.popper = createPopper(this.$refs.image, this.$refs.dropdown, {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 10]
                        }
                    },
                    { name: 'flip' },
                    { name: 'preventOverflow' }
                ],
                placement: 'bottom',
                strategy: 'fixed'
            });
        },

        selectImage () {
            if (!this.editable) {
                return;
            }

            const { state } = this.view;
            let { tr } = state;
            const selection = NodeSelection.create(state.doc, this.getPos());
            tr = tr.setSelection(selection);
            this.view.dispatch(tr);
        },

        setImageMaxSize () {
            const { width } = getComputedStyle(this.view.dom);
            this.maxWidth = parseInt(width, 10);
        },

        setImageOptions () {
            if (this.node.attrs.loader) {
                return;
            }

            resolveImage(this.src).then((result) => {
                if (!result.complete) {
                    result.width = 20;
                    result.height = 20;
                }

                this.imageOptions = {
                    src: this.src,
                    alt: this.node.attrs.alt,
                    caption: this.node.attrs.caption,
                    ref: this.node.attrs.ref,
                    width: this.node.attrs.width || result.width,
                    height: this.node.attrs.height || result.height,
                    originalWidth: result.width,
                    originalHeight: result.height
                };

                this.$nextTick(this.getLink);
            });
        },

        updateAltText (alt) {
            this.updateAttrs({ alt });
            this.selectImage();
        },

        onMouseDown (e, direction) {
            if (!this.editable) {
                return;
            }

            e.preventDefault();
            e.stopPropagation();

            Object.assign(this.resizerState, {
                x: e.clientX,
                y: e.clientY,
                w: Math.min(this.imageOptions.width, this.maxWidth),
                h: this.imageOptions.height,
                direction
            });

            this.resizing = true;

            this.startListeningForMouseEvents();
        },

        onMouseMove (e) {
            if (!this.editable) {
                return;
            }

            e.preventDefault();
            e.stopPropagation();

            if (!this.resizing) {
                return;
            }

            const ratio = this.imageOptions.originalWidth / this.imageOptions.originalHeight;
            const {
                x, y, w, h, direction
            } = this.resizerState;
            const dx = (e.clientX - x) * (/l/.test(direction) ? -1 : 1);
            const dy = (e.clientY - y) * (/t/.test(direction) ? -1 : 1);

            let width = clamp(w + dx, 20, 100000);
            let height = Math.max(h + dy, 20);

            const newRatio = width / height;

            if (newRatio > ratio) {
                width = parseInt(height * ratio, 10);
            } else if (newRatio < ratio) {
                height = parseInt(width / ratio, 10);
            }

            this.setImageSize(width, height);

            this.imageOptions.width = width;
            this.imageOptions.height = height;
        },

        onMouseUp (e) {
            e.preventDefault();
            e.stopPropagation();

            if (!this.resizing) {
                return;
            }

            this.resizing = false;

            Object.assign(this.resizerState, {
                x: 0,
                y: 0,
                w: 0,
                h: 0,
                direction: ''
            });

            this.stopListeningForMouseEvents();
            this.selectImage();
        },

        startListeningForMouseEvents () {
            document.addEventListener('mousemove', this.onMouseMove, true);
            document.addEventListener('mouseup', this.onMouseUp, true);
        },

        stopListeningForMouseEvents () {
            document.removeEventListener('mousemove', this.onMouseMove, true);
            document.removeEventListener('mouseup', this.onMouseUp, true);
        },

        updateLink (href) {
            const link = this.getValidatedLink(href);

            if ((href !== '' && link === null) || href.indexOf('mailto:') === 0) {
                this.$toasted.global.error('The link is invalid.');

                return;
            }

            this.selectImage();
            this.editor.commands.link({ href: link });
            this.currentImageLink = link;
        },

        removeLink () {
            this.imageLink = '';
            this.updateLink('');
        },

        updateSize (type) {
            const ratio = this.imageOptions.originalWidth / this.imageOptions.originalHeight;

            let { width, height } = this.imageOptions;

            if (type === 'width') {
                width = Math.min(width, this.maxWidth);
                height = parseInt(width / ratio, 10);
            } else {
                width = parseInt(height * ratio, 10);
                width = Math.min(width, this.maxWidth);
                height = parseInt(width / ratio, 10);
            }

            this.setImageSize(width, height);

            Object.assign(this.imageOptions, { width, height });

            this.selectImage();
            this.popper?.update();
        }
    }
};
</script>

<style scoped>
    .resizer-box-tl {
        cursor: nwse-resize;
        left: -6px;
        top: -6px;
    }

    .resizer-box-tr {
        cursor: nesw-resize;
        right: -6px;
        top: -6px;
    }

    .resizer-box-bl {
        cursor: nesw-resize;
        bottom: -6px;
        left: -6px;
    }

    .resizer-box-br {
        cursor: nwse-resize;
        bottom: -6px;
        right: -6px;
    }
</style>
