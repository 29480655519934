<template>
    <div class="flex">
        <div class="w-1/4 border-r p-4">
            <form-field-wrapper
                label="Email Subject"
                :error="form.errors.get('subject')"
                :should-show-error="form.errors.has('subject')"
            >
                <input
                    v-model="form.subject"
                    type="text"
                    class="form-field"
                >
            </form-field-wrapper>

            <form-field-wrapper
                v-if="!isCustomFromNameHidden"
                :error="form.errors.get('from_name')"
                :should-show-error="form.errors.has('from_name')"
            >
                <template #label>
                    <div class="flex items-center space-x-2">
                        <span class="tooltip-text">From Name</span>

                        <img
                            v-if="customFromNameTooltip"
                            v-tippy
                            class="w-5 h-5"
                            :src="asset('images/account/rsvpify-premium.svg')"
                            :content="customFromNameTooltip"
                        >
                    </div>
                </template>

                <input
                    v-model="form.from_name"
                    type="text"
                    class="form-field"
                    :disabled="isCustomFromNameDisabled"
                >
            </form-field-wrapper>

            <feature-enabled feature="email-builder-from-email" :passthrough="availableFromEmailDomains.length > 1">
                <form-field-wrapper
                    label="From Email"
                    :error="form.errors.get('from_email')"
                    :should-show-error="form.errors.has('from_email')"
                >
                    <div class="flex items-center w-full">
                        <input
                            v-model="form.from_email_local_part"
                            class="form-field"
                            placeholder="invitations"
                        >
                        <span class="shrink">@</span>
                    </div>
                    <div class="w-full">
                        <simple-picker
                            v-model="form.from_email_domain"
                            class="w-full mt-2"
                            :items="availableFromEmailDomains"
                        ></simple-picker>
                    </div>
                </form-field-wrapper>
            </feature-enabled>

            <form-field-wrapper
                label="Reply-To Email"
                :error="form.errors.get('reply_to')"
                :should-show-error="form.errors.has('reply_to')"
            >
                <input
                    v-model="form.reply_to"
                    type="text"
                    class="form-field"
                    autocomplete="chrome-off"
                >
            </form-field-wrapper>

            <template v-if="$store.get('Event/event').plan.features.CampaignEmailBranding.type !== 'rsvpify'">
                <form-field-wrapper label="Logo">
                    <simple-picker
                        v-model="form.settings.logo"
                        :items="logoPickerOptions"
                        item-label="label"
                        item-value="id"
                    ></simple-picker>

                    <div v-if="logoUrl">
                        <img :src="logoUrl">
                    </div>
                </form-field-wrapper>

                <fancy-image-uploader
                    v-if="form.settings.logo === 'new'"
                    v-slot="{ launch }"
                    class="ml-4"
                    conversion="email"
                    @select-image="url => $emit('logo-uploaded', url)"
                >
                    <button class="button-text button-info my-4" @click="launch">
                        <app-icon
                            name="add-circle"
                            class="h-6 w-6 mr-2"
                            stroke
                        ></app-icon>
                        Add Logo
                    </button>
                </fancy-image-uploader>

                <form-field-wrapper
                    v-if="logoUrl"
                    label="Logo Width"
                    tooltip="The height is automatically calculated to constrain proportions. Maximum width is 200px."
                >
                    <input
                        v-model.number="form.settings.logo_width"
                        type="number"
                        class="form-field"
                        min="50"
                        max="200"
                    >
                </form-field-wrapper>
            </template>

            <form-field-wrapper>
                <div class="flex items-center">
                    <color-picker v-model="form.settings.backgroundColor" :has-alpha="false"></color-picker>
                    <div class="ml-2 text-gray-500 text-sm uppercase">Background Color</div>
                </div>

                <div class="mt-2 flex items-center">
                    <color-picker v-model="form.settings.emailBodyBackgroundColor" :has-alpha="false"></color-picker>
                    <div class="ml-2 text-gray-500 text-sm uppercase">Email Body Background</div>
                </div>

                <div class="mt-2 flex items-center">
                    <color-picker v-model="form.settings.accentBarColor" :has-alpha="false"></color-picker>
                    <div class="mx-2 text-gray-500 text-sm uppercase">Accent Bar</div>
                    <toggle-switch v-model="form.settings.accentBarColorEnabled" small></toggle-switch>
                </div>
            </form-field-wrapper>

            <div class="mt-4 w-full">
                <button class="button w-full" @click="showPreviewModal = true">Send a Preview</button>
            </div>
        </div>

        <div class="flex-1 p-4">
            <email-editor
                v-model="form.body"
                :accent-bar-enabled="form.settings.accentBarColorEnabled"
                :accent-color="form.settings.accentBarColor"
                :event="event"
                :default-button-options="defaultButtonOptions"
                :background-color="form.settings.backgroundColor"
                :body-background-color="form.settings.emailBodyBackgroundColor"
                :logo-url="logoUrl"
                :logo-width="form.settings.logo_width"
                :hide-rsvpify-footer="!isBrandedEmailFooterEnabled"
                @updated-accent-color="form.settings.accentBarColor = $event"
                @updated-footer-colors="form.settings.footerColors = $event"
            ></email-editor>
        </div>

        <app-modal v-model="showPreviewModal">
            <template slot="header">
                Send a Preview
            </template>

            <p class="mb-4">
                We'll send a preview email to up to 3 recipients. Placeholder
                data will be included in place of any guest-specific details.
            </p>

            <div
                v-for="(email, index) in previewForm.emails"
                :key="index"
                class="max-w-sm"
            >
                <input
                    v-model="previewForm.emails[index]"
                    class="form-field mt-2"
                    placeholder="Enter an email address"
                    autocomplete="chrome-off"
                >

                <div v-if="previewForm.errors.get(`emails.${index}`)" class="alert alert-error alert-sm mt-2">
                    <p class="w-full font-normal text-center">{{ previewForm.errors.get(`emails.${index}`) }}</p>
                </div>
            </div>

            <template v-if="requiresCaptcha">
                <vue-recaptcha
                    class="mt-2"
                    :sitekey="siteKey"
                    @verify="verifyRecaptcha"
                ></vue-recaptcha>

                <div v-if="previewForm.errors.has('token')" class="alert alert-error alert-sm mt-2">
                    <p class="w-full font-normal text-center">Please verify you are human.</p>
                </div>
            </template>

            <template #footer="{ close }">
                <button class="button" @click="close">
                    Cancel
                </button>

                <stateful-button
                    class="button-primary"
                    :loading="sendingPreview"
                    @click="sendPreview"
                >
                    Send
                </stateful-button>
            </template>
        </app-modal>
    </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import Form from '@/validation/Form';

export default {
    name: 'ComposeEmail',

    components: {
        VueRecaptcha
    },

    props: {
        availableFromEmailDomains: {
            type: Array,
            required: true
        },

        event: {
            required: true,
            type: Object
        },

        form: {
            required: true,
            type: Object
        },

        defaultButtonOptions: {
            type: Object,
            default: () => { return {}; }
        },

        siteKey: {
            required: true,
            type: String
        }
    },

    data () {
        return {
            previewForm: new Form({
                emails: ['', '', ''],
                token: ''
            }),
            showPreviewModal: false,
            sendingPreview: false
        };
    },

    computed: {
        isCustomFromNameDisabled () {
            return this.auth().user().plan.features.CustomFromName.type === 'disabled';
        },

        isCustomFromNameHidden () {
            return this.auth().user().plan.features.CustomFromName.type === 'hidden';
        },

        requiresCaptcha () {
            return this.$store.get('Event/event').plan.features.RecaptchaEmailPreviews.enabled;
        },

        customFromNameTooltip () {
            return this.isCustomFromNameDisabled ? `Please upgrade to customize the 'From Name'.` : null;
        },

        logoUrl () {
            if (this.form.settings.logo === 'new' && this.form.settings.logo_url) {
                return this.form.settings.logo_url;
            } if (this.form.settings.logo === 'existing' && this.event.form.settings.email_logo_url) {
                return this.event.form.settings.email_logo_url;
            } if (this.form.settings.logo === 'existing' && this.event.form.settings.logo_url) {
                return this.event.form.settings.logo_url;
            }

            return null;
        },

        logoPickerOptions () {
            return [
                { id: 'none', label: 'No logo' },
                { id: 'existing', label: 'Use existing' },
                { id: 'new', label: 'Upload a new logo' }
            ];
        },

        isBrandedEmailFooterEnabled () {
            return this.event.owner.plan.features.BrandedEmailFooter.enabled;
        }
    },

    watch: {
        'form.from_email_local_part': function (newVal) {
            this.form.from_email = `${this.form.from_email_local_part}@${this.form.from_email_domain}`;
        },
        'form.from_email_domain': function (newVal) {
            this.form.from_email = `${this.form.from_email_local_part}@${this.form.from_email_domain}`;
        }
    },

    created () {
        this.previewForm.emails[0] = this.auth().user().email;
    },

    methods: {
        verifyRecaptcha (token) {
            this.previewForm.token = token;
        },

        sendPreview () {
            this.sendingPreview = true;

            this.previewForm.post(
                this.route('api.events.campaigns.drafts.preview', this.event),
                null,
                this.form.data()
            ).then(() => {
                this.showPreviewModal = false;

                this.$toasted.global.success('Preview emails sent.');
            }).catch((error) => {
                if (error.response && error.response.status === 422) {
                    this.form.errors.record(error.response.data.errors);
                } else if (error.response.status === 429) {
                    this.$toasted.global.error(
                        `We've detected possible suspicious activity on your account, and your preview email(s) were not sent. If you believe you’re seeing message in error, please reach out to our support team.`
                    );
                } else if (error.response.status === 403) {
                    this.$toasted.global.error(`Please confirm the email address associated with your RSVPify account before sending preview emails.`);
                } else {
                    this.$toasted.global.error('There was an error when sending out the preview emails.');
                }
            }).finally(() => {
                this.sendingPreview = false;
            });
        }
    }
};
</script>
