<template>
    <div class="col-12 mb-8 md:col-4" @click="showModal = true">
        <a class="h-full w-full flex flex-col p-4 bg-white border-2 border-transparent shadow-md cursor-pointer rounded-md text-gray-800 transition duration-150 ease-in-out hover:border-purple-400">
            <div class="inline-flex mb-4">
                <div class="rounded-full bg-purple-100 p-3">
                    <app-icon name="covid-virus-warning-1-stroke" class="h-10 w-10 text-purple-700"></app-icon>
                </div>
            </div>
            <div class="text-sm font-medium uppercase trackiner-widest text-gray-500 mb-2">Suppress Emails</div>
            <p class="text-xl font-light">Suppress a list of emails and prevent them from receiving emails from RSVPify</p>
        </a>

        <app-modal
            v-model="showModal"
            title="Suppress Emails"
            full-screen
        >
            <form-field-wrapper
                label="Emails"
                :should-show-error="form.errors.has('emails')"
                :error="form.errors.get('emails')"
                tooltip="Enter one email per line."
            >
                <textarea
                    ref="email"
                    v-model="form.emails"
                    class="form-field"
                    placeholder="user@domain.com"
                    rows="25"
                ></textarea>
            </form-field-wrapper>

            <template #footer="{ close }">
                <stateful-button
                    :loading="form.processing"
                    class="button button-primary"
                    @click="suppressEmails"
                >
                    Suppress Emails
                </stateful-button>

                <button class="button" @click="close">
                    Cancel
                </button>
            </template>
        </app-modal>
    </div>
</template>

<script>
import Form from '@/validation/Form';

export default {
    name: 'SuppressEmails',

    data () {
        return {
            form: new Form({
                emails: ''
            }),
            loading: false,
            showModal: false
        };
    },

    methods: {
        suppressEmails () {
            this.loading = true;

            this.form.post(this.route('api.admin.suppress-emails'))
                .then(() => {
                    this.loading = false;
                    this.$toasted.global.success('Emails have been suppressed.');
                })
                .catch((error) => {
                    this.loading = false;
                    this.errors = error.response.data.errors;
                    this.$toasted.global.error('Unable to suppress emails. Please try again.');
                });
        }
    }
};
</script>
