<template>
    <edit-hint
        ref="hint"
        :visible="withHint && !readOnly"
        :classes="hintClasses"
    >
        <template #default>
            <div class="editor">
                <mounting-portal :mount-to="`#tiptap-bubble-menu-portal-${uniqueId}`" :to="`${uniqueId}`">
                    <editor-menu-bubble
                        v-if="editable"
                        v-slot="{ commands, isActive, getMarkAttrs, menu }"
                        :editor="editor"
                        :keep-in-bounds="true"
                        @hide="hideLinkEditor"
                    >
                        <div
                            class="bubble-menu"
                            :class="{ 'is-active': menu.isActive && !isActive.emailButton() && !isActive.image() }"
                            :style="{ left: `${menu.left}px`, bottom: `${menu.bottom}px` }"
                        >
                            <div v-if="showLinkInput" class="flex items-center">
                                <input
                                    ref="linkInput"
                                    v-model="currentUrl"
                                    class="w-48 bg-transparent text-white focus:outline-none"
                                    :placeholder="linkEditorType === 'url' ? 'https://' : 'email@address.com'"
                                    @keyup.enter="saveLink(currentUrl)"
                                    @keyup.esc="hideLinkEditor"
                                >
                                <button
                                    class="flex items-center ml-2 text-white"
                                    @click="saveLink('')"
                                >
                                    <app-icon name="close-circle" class="h-4 w-4"></app-icon>
                                </button>
                            </div>
                            <template v-else>
                                <template v-if="isAdvancedPreset">
                                    <editor-font-picker
                                        :editor="{ commands, getMarkAttrs }"
                                        :is-menu-active="menu.isActive"
                                        :only-email-fonts="showEmailSupportedOptions"
                                    ></editor-font-picker>
                                </template>

                                <template v-if="isAdvancedPreset || withFontSizePicker">
                                    <editor-text-size-picker
                                        :editor="{ commands, getMarkAttrs }"
                                        :is-menu-active="menu.isActive"
                                    ></editor-text-size-picker>
                                </template>

                                <button
                                    class="menu-button"
                                    :class="{ 'is-active': isActive.bold() }"
                                    @click="commands.bold"
                                >
                                    <app-icon name="text-bold" class="h-4 w-4"></app-icon>
                                </button>

                                <button
                                    class="menu-button"
                                    :class="{ 'is-active': isActive.italic() }"
                                    @click="commands.italic"
                                >
                                    <app-icon name="text-italic" class="h-4 w-4"></app-icon>
                                </button>

                                <button
                                    class="menu-button"
                                    :class="{ 'is-active': isActive.underline() }"
                                    @click="commands.underline"
                                >
                                    <app-icon name="text-underline" class="h-4 w-4"></app-icon>
                                </button>

                                <template v-if="isAdvancedPreset">
                                    <button
                                        class="menu-button"
                                        :class="{ 'is-active': isActive.alignment({ align: 'left' }) }"
                                        @click="commands.alignment({ align: 'left' })"
                                    >
                                        <app-icon
                                            name="paragraph-align-left-stroke"
                                            class="h-4 w-4"
                                        ></app-icon>
                                    </button>

                                    <button
                                        class="menu-button"
                                        :class="{ 'is-active': isActive.alignment({ align: 'center' }) }"
                                        @click="commands.alignment({ align: 'center' })"
                                    >
                                        <app-icon name="paragraph-align-center-stroke" class="h-4 w-4"></app-icon>
                                    </button>

                                    <button
                                        class="menu-button"
                                        :class="{ 'is-active': isActive.alignment({ align: 'right' }) }"
                                        @click="commands.alignment({ align: 'right' })"
                                    >
                                        <app-icon name="paragraph-align-right-stroke" class="h-4 w-4"></app-icon>
                                    </button>

                                    <button
                                        class="menu-button"
                                        :class="{ 'is-active': isActive.code() }"
                                        @click="commands.code"
                                    >
                                        <app-icon name="angle-brackets-stroke" class="h-4 w-4"></app-icon>
                                    </button>

                                    <editor-text-color-picker
                                        :editor="editor"
                                        :is-menu-active="menu.isActive"
                                    ></editor-text-color-picker>
                                </template>

                                <editor-merge-tags-button v-if="showMergeTags" :editor="editor"></editor-merge-tags-button>

                                <base-dropdown
                                    ref="dropdown"
                                    class="inline-block"
                                    placement="bottom-start"
                                    :position-fixed="false"
                                    trigger-class="flex items-center"
                                >
                                    <template slot="trigger" slot-scope="{ triggerFunction, isOpen }">
                                        <button
                                            class="menu-button ml-2"
                                            :class="{ 'is-active': editor.isActive.link() }"
                                            @click="triggerFunction"
                                        >
                                            <app-icon name="link" class="h-4 w-4"></app-icon>
                                        </button>
                                    </template>

                                    <template #default="{ triggerFunction }">
                                        <div v-bar="{ useScrollbarPseudo: true }" class="bg-black border-b-2 border-purple-light rounded-xl py-2 px-1 w-48 -ml-2 mt-2">
                                            <div>
                                                <div
                                                    class="cursor-pointer w-full px-2 py-1 text-white hover:bg-smoke-300 rounded-xl text-cursor text-sm"
                                                    @click="showLinkEditor('url')"
                                                >
                                                    Web Page
                                                </div>

                                                <div
                                                    class="cursor-pointer w-full px-2 py-1 text-white hover:bg-smoke-300 rounded-xl text-cursor text-sm"
                                                    @click="showLinkEditor('email')"
                                                >
                                                    Email
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </base-dropdown>
                            </template>
                        </div>
                    </editor-menu-bubble>
                </mounting-portal>

                <editor-floating-menu
                    v-if="isFloatingMenuAvailable"
                    v-slot="{ commands, isActive, menu }"
                    :editor="editor"
                >
                    <div
                        class="floating-menu"
                        :class="{ 'is-active': menu.isActive }"
                        :style="{ top: `${menu.top}px` }"
                    >
                        <div
                            v-if="showFloatingMenu"
                            class="shadow-lg bg-white border rounded-md flex items-center"
                            :style="styles.floatingMenu"
                        >
                            <button
                                v-tippy
                                class="menu-button"
                                :class="{ 'is-active': isActive.bullet_list() }"
                                content="Bullet List"
                                @click="insertBulletList"
                            >
                                <app-icon name="list-bullets" class="h-5 w-5"></app-icon>
                            </button>

                            <button
                                v-tippy
                                class="menu-button"
                                :class="{ 'is-active': isActive.ordered_list() }"
                                content="Ordered List"
                                @click="insertOrderedList"
                            >
                                <app-icon name="list-numbers" class="h-5 w-5"></app-icon>
                            </button>

                            <editor-embed-video-button
                                v-if="showEmbedVideoButton"
                                v-tippy
                                content="Embed Video"
                                :editor="{ commands }"
                            ></editor-embed-video-button>

                            <editor-image-upload-button
                                v-tippy
                                :editor="{ commands }"
                                :image-type="imageType"
                                content="Upload Image"
                                @insert-image="showFloatingMenu = false"
                            ></editor-image-upload-button>

                            <editor-merge-tags-button
                                v-if="showMergeTags"
                                v-tippy
                                :editor="editor"
                                content="Add Merge Tag"
                                @insert-tag="showFloatingMenu = false"
                            ></editor-merge-tags-button>

                            <editor-insert-email-button
                                v-if="showEmailSupportedOptions"
                                v-tippy
                                :editor="editor"
                                :default-button-options="defaultButtonOptions"
                                :theme-settings="themeSettings"
                                content="Insert Button"
                                @insert-button="showFloatingMenu = false"
                            ></editor-insert-email-button>

                            <editor-insert-qr-code-button
                                v-if="showCheckinQrCodeButton"
                                v-tippy
                                :editor="editor"
                                :theme-settings="themeSettings"
                                content="QR Code for Check-in"
                                @insert-button="showFloatingMenu = false"
                            ></editor-insert-qr-code-button>
                        </div>

                        <div class="absolute flex items-center top-0 right-0" :style="styles.floatingMenuControl">
                            <div
                                class="w-8 h-8 bg-white rounded-full border border-black text-black cursor-pointer flex items-center justify-center"
                                @click="showFloatingMenu = !showFloatingMenu"
                            >
                                <app-icon :name="showFloatingMenu ? 'close' : 'add'" class="h-4 w-4 fill-current"></app-icon>
                            </div>
                        </div>
                    </div>
                </editor-floating-menu>

                <editor-content
                    :allow-uploads="!simple"
                    class="editor__content"
                    :editor="editor"
                    :image-type="imageType"
                    :style="{ color: defaultTextColor, ...textStyles }"
                ></editor-content>
            </div>
        </template>
    </edit-hint>
</template>

<script>
import {
    Editor,
    EditorContent,
    EditorMenuBubble
} from 'tiptap';

import {
    Alignment,
    Bold,
    Italic,
    Underline,
    Code,
    Heading,
    ListItem,
    BulletList,
    OrderedList,
    History,
    HardBreak
} from 'tiptap-extensions';
import ValidatesLinks from '@/mixins/ValidatesLinks';
import EditorFloatingMenu from './Extensions/EditorFloatingMenu';
import { getMarkExtent } from './Utils/getMarkExtent';
import EditorLink from './Extensions/EditorLink';
import Image from './Extensions/Image';
import Iframe from './Extensions/Iframe';
import TextSize from './Extensions/TextSize';
import TextColor from './Extensions/TextColor';
import FontFamily from './Extensions/FontFamily';
import EmailButtonNode from './Extensions/EmailButtonNode';
import CheckinQrCodeNode from './Extensions/CheckinQrCodeNode';
import RetainNewParagraphFormatting from './Extensions/RetainNewParagraphFormatting';
import GeneratesUniqueKey from '@/mixins/GeneratesUniqueKey';

export default {
    name: 'TiptapEditor',

    components: {
        EditorContent,
        EditorMenuBubble,
        EditorFloatingMenu
    },

    mixins: [ValidatesLinks, GeneratesUniqueKey],

    props: {
        defaultButtonOptions: {
            type: Object,
            default: () => { return {}; }
        },

        defaultTextColor: {
            type: String,
            default: 'inherit'
        },

        imageType: {
            type: String,
            default: 'original'
        },

        readOnly: {
            type: Boolean,
            default: false
        },

        showMergeTags: {
            type: Boolean,
            default: false
        },

        showEmailSupportedOptions: {
            type: Boolean,
            default: false
        },

        showEmbedVideoButton: {
            type: Boolean,
            default: false
        },

        showCheckinQrCodeButton: {
            type: Boolean,
            default: false
        },

        simple: {
            type: Boolean,
            default: false
        },

        textStyles: {
            type: [Object, Array, String],
            default: () => { return {}; }
        },

        withHint: {
            type: Boolean,
            default: false
        },

        withFontSizePicker: {
            type: Boolean,
            default: false
        },

        hintClasses: {
            type: String,
            default: ''
        },

        themeSettings: {
            type: Object,
            default: () => { return {}; }
        },

        value: {
            type: String,
            default: ''
        }
    },

    data () {
        let hasSeenFloatingMenu = true;

        try {
            hasSeenFloatingMenu = localStorage.getItem('seenFloatingMenu') !== null;
        } catch (error) {
            // I am not empty.
        }

        return {
            editor: new Editor({
                extensions: [
                    new Alignment(),
                    new Iframe(),
                    new Bold(),
                    new Italic(),
                    new Underline(),
                    new Code(),
                    new HardBreak(),
                    new EditorLink(),
                    new Heading({ levels: [1, 2, 3] }),
                    new ListItem(),
                    new BulletList(),
                    new OrderedList(),
                    new TextSize(),
                    new TextColor(),
                    new FontFamily(),
                    new History(),
                    new Image(),
                    new EmailButtonNode(),
                    new CheckinQrCodeNode(),
                    new RetainNewParagraphFormatting()
                ],
                content: this.value,
                onUpdate: ({ getHTML }) => {
                    const html = getHTML();
                    const divElement = document.createElement('div');
                    divElement.innerHTML = html;

                    const hasImageOrIFrame = divElement.querySelector('img, iframe');

                    this.$emit('input', divElement.textContent.length === 0 && !hasImageOrIFrame ? '' : html);
                },
                onFocus: () => {
                    localStorage.setItem('seenFloatingMenu', 'true');

                    this.$refs.hint.changeFocus(true);

                    this.$emit('focus');
                },
                onBlur: () => {
                    this.$refs.hint.changeFocus(false);

                    this.$emit('blur');
                },
                editorProps: {
                    transformPastedHTML: (html) => {
                        if (this.simple) {
                            // Clean up all HTML tags
                            const el = document.createElement('div');
                            el.innerHTML = html;
                            return el.textContent || el.innerText;
                        }

                        return html;
                    }
                }
            }),
            showLinkInput: false,
            showFloatingMenu: !hasSeenFloatingMenu,
            currentUrl: '',
            linkEditorType: 'url',
            uniqueId: this.getUniqueKey()
        };
    },

    computed: {
        editable () {
            return !this.readOnly;
        },

        isAdvancedPreset () {
            return !this.simple;
        },

        isFloatingMenuAvailable () {
            return this.isAdvancedPreset && this.editable;
        },

        styles () {
            return {
                floatingMenuControl: {
                    marginRight: `-10px`
                },
                floatingMenu: {
                    marginRight: `2rem`
                }
            };
        }
    },

    watch: {
        editable: {
            immediate: true,
            handler () {
                this.editor.setOptions({
                    editable: this.editable
                });
            }
        }
    },

    mounted () {
        this.$refs.hint.setEditor(this.editor);
    },

    created () {
        const parentDiv = document.createElement('div');
        parentDiv.id = `tiptap-bubble-parent-${this.uniqueId}`;

        const portalTarget = document.createElement('div');
        portalTarget.id = `tiptap-bubble-menu-portal-${this.uniqueId}`;

        parentDiv.appendChild(portalTarget);
        document.body.appendChild(parentDiv);
    },

    beforeDestroy () {
        document.body.removeChild(document.getElementById(`tiptap-bubble-parent-${this.uniqueId}`));

        this.editor.destroy();
    },

    methods: {
        focus () {
            this.editor.focus();
        },

        insertBulletList () {
            this.editor.commands.bullet_list();
            this.showFloatingMenu = false;
        },

        insertOrderedList () {
            this.editor.commands.ordered_list();
            this.showFloatingMenu = false;
        },

        showLinkEditor (linkEditorType) {
            this.linkEditorType = linkEditorType;

            const { href } = this.editor.getMarkAttrs('link');

            if (href && href.indexOf('mailto:') === 0) {
                this.currentUrl = href.substring(7);
            } else {
                this.currentUrl = href;
            }

            this.showLinkInput = true;

            this.$nextTick(() => {
                this.$refs.linkInput.focus();
            });
        },

        hideLinkEditor () {
            this.showLinkInput = false;
        },

        saveLink (href) {
            let link = href.trim();

            if (this.linkEditorType === 'email') {
                link = `mailto:${link}`;
            } else {
                link = this.getValidatedLink(href);

                if (href !== '' && link === null) {
                    this.$toasted.global.error('The link is invalid.');

                    return;
                }
            }

            const currentLinkPos = getMarkExtent(this.editor, this.editor.selection.from, 'link');

            if (currentLinkPos) {
                this.editor.setSelection(currentLinkPos.from, currentLinkPos.to);
            }

            this.editor.commands.link({ href: link });
            this.hideLinkEditor();
        }
    }
};
</script>
